import type { BrowserOptions } from "@sentry/browser";

import * as Sentry from "@sentry/browser";
import { isbot } from "isbot";

import type { SentryRootProject } from "@/lib/integrations/sentry/types";

import { isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser";
import { solvariContextIntegration } from "@/lib/integrations/sentry/service/contextIntegration";
import { ignoreConfig } from "@/lib/integrations/sentry/service/ignored";
import { getProjectRelease } from "@/lib/integrations/sentry/service/project";
import { trackImageLoading } from "@/lib/integrations/sentry/service/trackers";

/*
  You can only have one Sentry instance running on a page.
  We use micro frontends that each have their own Sentry project.
  So we need some logic to send the errors to the right project by defining them globally
 */

function makeMicroFrontEndTransport() {
  return Sentry.makeMultiplexedTransport(
    Sentry.makeFetchTransport,
    ({ getEvent }) => {
      if (!window.sentryProjects) {
        return [];
      }
      const projectName = getEvent()?.tags?.project as string | undefined;
      const project = projectName
        ? window.sentryProjects[projectName]
        : undefined;
      return project?.dsn ? [project.dsn] : [];
    },
  );
}

function getConfig(rootProject: SentryRootProject): BrowserOptions {
  return {
    dsn: rootProject.dsn,
    environment: rootProject.environment,
    transport: makeMicroFrontEndTransport(),
    release: getProjectRelease(rootProject),
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.sessionTimingIntegration(),
      solvariContextIntegration(rootProject),
    ],
    sendDefaultPii: true,
    replaysOnErrorSampleRate: 0.1,
    ...ignoreConfig,
  };
}

function initSentryRoot(rootProject: SentryRootProject) {
  if (!isSupportedBrowser() || isbot(navigator.userAgent) || !rootProject.dsn) {
    return;
  }

  Sentry.init(getConfig(rootProject));

  window.sentry = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
  };

  trackImageLoading();
}

export { initSentryRoot };
